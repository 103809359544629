// Polyfill needeed for Safari 13...
import EventTarget from '@ungap/event-target';
import postbox from '../../postbox.mjs';
import { debounce } from '../../utils/debounce.mjs';
import { getPageLanguage } from '../../utils/get-page-language.mjs';

const SEARCH_DEBOUNCE_TIMEOUT = 50;

export default class SearchResultHandler extends EventTarget {
  #container;
  #resultsList;
  #resultInfo;
  #loader;
  #showMoreContainer;

  #handleQueryChangedBound = null;

  /**
   * @type {AbortController}
   */
  #abortController = null;
  #debouncedFetchResults = debounce(this.#fetchResult.bind(this), SEARCH_DEBOUNCE_TIMEOUT);

  /**
   * @type {IntersectionObserver}
   */
  #intersectionObserver;

  constructor(containerElement) {
    super();

    this.#container = containerElement;
    this.#resultsList = containerElement.querySelector('#estate-results');
    this.#resultInfo = containerElement.querySelector('#results-info-container');
    this.#loader = containerElement.querySelector('#loader');
    this.#showMoreContainer = containerElement.querySelector('#show-more-container');
    this.#handleQueryChangedBound = this.#handleQueryChanged.bind(this);

    this.#init();
  }

  #init() {
    postbox.addEventListener('search-query-changed', this.#handleQueryChangedBound);
    this.#initMoreResultsButton();
    this.#initIntersectionObserver();
  }

  destroy() {
    postbox.removeEventListener('search-query-changed', this.#handleQueryChangedBound);
    this.#intersectionObserver.disconnect();
  }

  #initIntersectionObserver() {
    this.#intersectionObserver = new IntersectionObserver(entries => {
      if(entries.length > 1) {
        throw new Error('Multiple entries found, this is not supported.');
      }

      const entry = entries[0];
      const scrollPosition = window.scrollY;
      const hideStickyInfo = entry.isIntersecting || scrollPosition > this.#resultInfo.offsetTop;

      this.#resultInfo.classList.toggle('hide-sticky', hideStickyInfo);
    }, {
      root: null,
      rootMargin: '0px',
      threshold: 0.5
    });

    this.#intersectionObserver.observe(this.#resultInfo);
  }

  #initMoreResultsButton() {
    const anchor = this.#container.querySelector('#more-results-link');
    if(anchor === null) {
      return;
    }

    const classNames = [...anchor.classList];

    const button = document.createElement('button');
    button.id = anchor.id;
    button.textContent = anchor.textContent;
    button.addEventListener('click', this.#fetchNextPage.bind(this));

    if(classNames.length > 0) {
      button.classList.add(classNames);
    }

    anchor.replaceWith(button);
  }

  async #handleQueryChanged(event) {
    const query = event.detail.query;

    await this.#debouncedFetchResults(query, this.#updateResults.bind(this));
  }

  async #fetchNextPage() {
    postbox.dispatchEvent(new CustomEvent('request-next-page'));
  }

  async #fetchResult(formData, callback) {
    if(this.#abortController !== null) {
      this.#abortController.abort();
    }

    const requestedPageIndex = parseInt(formData.get('pageIndex'), 10) || 0;
    if(requestedPageIndex === 0) {
      this.#resultInfo.classList.add('loading');
      this.#resultsList.classList.add('loading');
    }

    this.toggleLoader(true);
    postbox.dispatchEvent(new CustomEvent('toggle-search-alert-button', { detail: { enabled: false } }));

    this.#abortController = new AbortController();
    const signal = this.#abortController.signal;

    const culture = getPageLanguage();
    const url = `/api/${culture}/estate-search/html?` + new URLSearchParams(formData);

    let response;
    try {
      response = await fetch(url, { signal });
    } catch(error) {
      if(error.name === 'AbortError') {
        return;
      }

      throw error;
    }

    this.toggleLoader(false);

    if(!response.ok) {
      console.error(`Failed to fetch estate result: ${response.status} ${response.statusText}`);
      return;
    }

    this.#showMoreResultsButton(response.headers.get('x-has-more-results') === 'True');

    const canCreateSearchAlertFromQuery = response.headers.get('x-can-create-search-alert-from-query');
    if(canCreateSearchAlertFromQuery === 'True') {
      postbox.dispatchEvent(new CustomEvent('toggle-search-alert-button', { detail: { enabled: true } }));
    }

    const result = await response.text();
    const pageIndex = parseInt(response.headers.get('x-page-index'), 10);
    callback(result, pageIndex);
  }

  toggleLoader(show) {
    this.#loader.classList.toggle('show', show);
  }

  #showMoreResultsButton(show) {
    this.#showMoreContainer.classList.toggle('show', show);
  }

  #updateResults(result, pageIndex) {
    if(pageIndex === 0) {
      this.#resultsList.innerHTML = '';
    }

    const tempContainer = document.createElement('div');
    tempContainer.innerHTML = result;

    const listItems = tempContainer.querySelector('#results').innerHTML;
    this.#resultsList.insertAdjacentHTML('beforeend', listItems);
    this.#resultsList.classList.remove('loading');

    const resultsInfo = tempContainer.querySelector('#results-info-container').innerHTML;
    this.#resultInfo.innerHTML = resultsInfo;
    this.#resultInfo.classList.remove('loading');
  }
}
