import { firstAnscestorOrDefault } from '../utils/dom-utils.mjs';

/**
 * @param {Element} rootElement
 */
export default function apply(rootElement = document.documentElement) {
  /**
   * @param {HTMLElement} element
   * @returns {boolean} true if the element should be ignored, otherwise false
   */
  const isIgnoredElement = (element) => {
    let parent = element;
    do {
      const attributes = [...element.attributes];

      if(attributes.some(attribute => attribute.name === 'data-toggle-ignore')) {
        console.log('Ignored click for', parent);
        return true;
      }

      if(attributes.some(attribute => attribute.name === 'data-toggle-open')) {
        break;
      }

      parent = parent.parentElement;
    } while(!!parent);

    return false;
  };

  const getTargetElement = (attributes, toggledElement) => {
    let targetElement = toggledElement;
    const targetSelector = attributes.find(x => x.name === 'data-toggle-open')?.value;
    if(!!targetSelector) {
      if(targetSelector.startsWith('#')) {
        targetElement = rootElement.querySelector(targetSelector);
      } else {
        targetElement = toggledElement.querySelector(targetSelector);
      }

      if(!targetElement) {
        throw new Error(`Could not find target element with selector ${targetSelector}.`);
      }
    }

    return targetElement;
  };

  const getSynchronizedElements = targetElement => {
    let elements = [];

    const targetSelector = targetElement.getAttribute('data-toggle-open-synchronize');
    if(!!targetSelector) {
      elements = [...rootElement.querySelectorAll(targetSelector)];
    }

    return elements;
  };

  /**
   * @param {HTMLElement[]} targetElements
   * @param {boolean} setOpenClass
   * @param {string} targetClassName
   */
  const toggleElementClasses = (targetElements, setOpenClass, targetClassName) => {
    for(const targetElement of targetElements) {
      targetElement.classList.toggle(targetClassName, setOpenClass);

      if(targetElement.getAttributeNames().some(x => x === 'aria-expanded')) {
        targetElement.setAttribute('aria-expanded', setOpenClass ? 'true' : 'false');
      }
      if(targetElement.getAttributeNames().some(x => x === 'aria-hidden')) {
        targetElement.setAttribute('aria-hidden', setOpenClass ? 'false' : 'true');
      }
    }
  };

  const handleClick = event => {
    /**
     * @type {HTMLElement}
     */
    const clickedElement = event.target;

    if(isIgnoredElement(clickedElement)) {
      return;
    }

    let toggledElement = null;

    const clickedElementAttributes = [...clickedElement.attributes].map(attribute => attribute.name);
    if(clickedElementAttributes.includes('data-toggle-open')) {
      toggledElement = clickedElement;
    } else {
      toggledElement = firstAnscestorOrDefault(clickedElement, element => {
        const attributes = [...element.attributes].map(attribute => attribute.name);

        return attributes.includes('data-toggle-open');
      });
    }

    if(toggledElement === null) {
      return;
    }

    const attributes = [...toggledElement.attributes];
    const attributeNames = attributes.map(attribute => attribute.name);

    const targetElement = getTargetElement(attributes, toggledElement);

    const targetClassName = attributes.find(x => x.name === 'data-toggle-open-class-name')?.value ?? 'open';

    let setOpenClass = !targetElement.classList.contains(targetClassName);
    if(attributeNames.includes('data-toggle-open-force')) {
      setOpenClass = true;
    }

    const elementsToSynchronize = getSynchronizedElements(targetElement);

    toggleElementClasses([targetElement, ...elementsToSynchronize], setOpenClass, targetClassName);

    if(attributeNames.includes('data-toggle-open-navigate')) {
      // If we also want navigation to occur (like going to an anchor)
      // we need to exit early here to allow the event to propagate.
      return;
    }

    const focusId = attributes.find(x => x.name === 'data-toggle-open-focus-id')?.value;
    if(!!focusId) {
      const focusElement = document.getElementById(focusId);
      if(!!focusElement) {
        setTimeout(() => focusElement.focus(), 0);
      }
    }

    const scrollToId = attributes.find(x => x.name === 'data-toggle-open-scroll-to-id')?.value;
    if(!!scrollToId) {
      setTimeout(() => {
        window.location.hash = '';
        window.location.hash = scrollToId;
      }, 0);
    }

    event.preventDefault();
    event.stopPropagation();
  };

  rootElement.addEventListener('click', handleClick);
}
