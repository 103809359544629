/**
 * Global configuration object generated through SiteConfigurationTagHelper
 * @typedef {object} SiteConfiguration
 * @property {string} culture The culture of the current page.
 * @property {string} environment The current environment for the site such as Development, Integration, Preproduction and Production.
 * @property {('Sweden'|'International')} website The current website.
 * @property {EstateConfiguration} estate Information about the current estate.
 * @property {OfficeConfiguration} office Information about the current office.
 * @property {BrokerConfiguration} broker Information about the current broker.
 * @property {FriendlyCaptchaConfiguration} friendlyCaptcha Friendly Captcha configuration.
 */

/**
 * @typedef {object} EstateConfiguration
 * @property {string} id The id of the current estate.
 * @property {number} shortId The short id of the current estate.
 * @property {boolean} showBids Whether or not bids should be shown for the current estate.
 */

/**
 * @typedef {object} OfficeConfiguration
 * @property {string} id The id of the current office.
 * @property {string} name The name of the current office.
 */

/**
 * @typedef {object} BrokerConfiguration
 * @property {string} id The id of the current office.
 * @property {string} name The name of the current office.
 */

/**
 * @typedef {object} FriendlyCaptchaConfiguration
 * @property {string} siteKey The site key for Friendly Captcha.
 * @property {boolean} useEUEndpoint If true puzzles should be requested from the EU endpoint.
 */

/** @type {SiteConfiguration} */
export default window.siteConfiguration || {};
